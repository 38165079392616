import styled from "styled-components"
import left from "../../../static/arrow_left.svg"
import right from "../../../static/arrow_rigth.svg"
import quotation from "../../../static/quotation.svg"

const ReferencesStyled = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 60px;
  padding-bottom: 120px;
  @media (max-width: 1080px) {
    flex-direction: column;
  }
  .reference {
    &__mobile {
      display: none;
      @media (max-width: 1080px) {
        display: block;
        padding-bottom: 30px;
        br {
          display: block;
        }
      }
      @media (max-width: 500px) {
        margin-bottom: -90px;
      }
    }
    &__desctop {
      width: 100%;
      @media (max-width: 1080px) {
        display: none;
      }
    }
  }
  .col {
    width: calc(50% - 20px);
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @media (max-width: 1080px) {
      width: 100%;
    }
    h2 {
      max-width: 370px;
      margin-left: auto;
      height: 140px;
      margin-bottom: 0;
      @media (max-width: 1080px) {
        padding-top: 70px;
        margin-right: auto;
        height: initial;
        br {
          display: block;
        }
      }
      @media (max-width: 500px) {
        padding-top: 10px;
      }
    }
  }
  .references__slider__img {
    position: relative;
    height: 600px;
    width: 100%;
    img {
      max-width: 450px;
      @media (max-width: 500px) {
        max-width: 300px;
      }
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
    }
  }
  .references__slider__left {
    position: absolute;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 9999;
    background: url(${left});
    background-position: center;
    background-repeat: no-repeat;
  }
  .references__slider__right {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 9999;
    background: url(${right});
    background-position: center;
    background-repeat: no-repeat;
  }
  .references__slider__text {
    margin-left: auto;
    position: relative;
    @media (max-width: 1080px) {
      margin-right: auto;
      padding-top: 30px;
      margin-top: 80px;
    }
    @media (max-width: 500px) {
      margin-top: -50px;
    }
    &:after {
      content: "";
      width: 30px;
      height: 30px;
      display: block;
      position: absolute;
      top: 0;
      left: -50px;
      background: url(${quotation});
      background-position: center;
      background-repeat: no-repeat;
      @media (max-width: 500px) {
        left: 0;
      }
    }
    & > div {
      position: relative;
      max-width: 370px;
      top: 0px !important;
      @media (max-width: 1080px) {
        top: 40px !important;
      }
      @media (max-width: 500px) {
        top: 90px !important;
      }
      & > div {
        position: relative;
      }
    }
  }
  .references__author {
    text-align: right;
  }
`

export default ReferencesStyled
