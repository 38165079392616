import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import uuid from "react-uuid"
import myInitObject from "../ultis/variable"
import TitleStyled from "../components/styles/titleStyles"
import ReferencesStyled from "../components/styles/ReferencesStyles"
import Carousel from "react-spring-3d-carousel"
import { config } from "react-spring"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const { referencesSingleton } = data.swapi
  const [sliderImage, setSliderImage] = useState([])
  const [sliderText, setSliderText] = useState([])
  const [goToSlide, setGoToSlide] = useState(0)
  const sliderLength = referencesSingleton.references.length

  useEffect(() => {
    const sliderImage = referencesSingleton.references.map((item, index) => {
      const imgSrt = `<img src="${myInitObject.cockpitUrl +
        item.value.image.path}" alt=""/>`

      return {
        key: uuid(),
        content: <div dangerouslySetInnerHTML={{ __html: imgSrt }}></div>,
      }
    })
    setSliderImage(sliderImage)

    const sliderText = referencesSingleton.references.map(item => {
      const descriptionStr = `<p class="references__description">${item.value.description}</p> <p class="references__author">${item.value.author}</p>`
      return {
        key: uuid(),
        content: (
          <div dangerouslySetInnerHTML={{ __html: descriptionStr }}></div>
        ),
      }
    })
    setSliderText(sliderText)
  }, [])

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={referencesSingleton.metaTitle}
        description={referencesSingleton.metaDescription}
        img={
          referencesSingleton.metaImage
            ? referencesSingleton.metaImage.path
            : ""
        }
        lang="en"
      />
      <div className="container">
        <ReferencesStyled>
          <div className="reference__mobile">
            <TitleStyled
              textAlign="center"
              linePosition="center"
              color="#000000"
            >
              <h2
                dangerouslySetInnerHTML={{ __html: referencesSingleton.title }}
              ></h2>
            </TitleStyled>
          </div>
          <div className="col">
            <div className="references__slider__img">
              {typeof window !== "undefined" && Carousel && (
                <Carousel
                  slides={sliderImage}
                  goToSlide={goToSlide}
                  offsetRadius={1}
                  showNavigation={false}
                  animationConfig={config.fast}
                />
              )}
            </div>
            <div
              className="references__slider__left"
              onClick={() => {
                let nextSlide = goToSlide
                if (goToSlide === 0) {
                  nextSlide = sliderLength
                } else {
                  nextSlide--
                }
                setGoToSlide(nextSlide)
              }}
            ></div>
            <div
              className="references__slider__right"
              onClick={() => {
                let nextSlide = goToSlide

                if (goToSlide < sliderLength) {
                  nextSlide++
                } else {
                  nextSlide = 0
                }
                setGoToSlide(nextSlide)
              }}
            ></div>
          </div>
          <div className="col">
            <div className="reference__desctop">
              <TitleStyled
                textAlign="center"
                linePosition="center"
                color="#000000"
              >
                <h2
                  dangerouslySetInnerHTML={{
                    __html: referencesSingleton.title,
                  }}
                ></h2>
              </TitleStyled>
            </div>
            <div className="references__slider__text">
              {typeof window !== "undefined" && Carousel && (
                <Carousel
                  slides={sliderText}
                  goToSlide={goToSlide}
                  offsetRadius={0}
                  showNavigation={false}
                  animationConfig={config.fast}
                />
              )}
            </div>
          </div>
        </ReferencesStyled>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query referenceEN {
    swapi {
      referencesSingleton(lang: "en") {
        metaTitle
        metaDescription
        metaImage {
          path
        }
        title
        references {
          value
        }
      }
    }
  }
`
